<script lang="ts">
</script>

<template lang="pug">
	picture
		//- avif 32kb
		source(
			srcset!="https://media.graphassets.com/12Tx2rUoSJOdUqsPgc5U",
			type="image/avif"
		)
		//- webp 35kb
		source(
			srcset!="https://media.graphassets.com/qMbl9VE1Rzh5JAGena6y",
			type="image/webp"
		)
		img(
			class="w-full h-auto select-none",
			alt="map of North America",
			data-mimetype="image/png",
			draggable="false",
			height="757",
			loading="lazy",
			src="https://media.graphassets.com/Wo22bOnSQpenF2KhSGVy",
			width="1200"
		)</template>
