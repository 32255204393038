<script lang="ts">
	// context api
	import { getContext } from "svelte";

	// components
	import TerminalsMapClusterCount from "$atoms/TerminalsMapClusterCount.svelte";
	import TerminalsMapPin from "$atoms/TerminalsMapPin.svelte";
	import TerminalMapPopover from "$atoms/TerminalMapPopover.svelte";

	// types
	import type { MapLocation } from "$types/types";
	import type { Writable } from "svelte/store";

	// stores from context
	const liquidsMenuOpen = getContext(
		"liquidsMenuOpenStore",
	) as Writable<boolean>;
	const locationsFilteredStore = getContext(
		"locationsFilteredStore",
	) as Writable<MapLocation[]>;

	// constants from context
	const isEmbedded = getContext("isEmbedded");

	// utils
	import { slugify } from "$utils/utils";
	import { hideAllMapLabels } from "$utils/mapUtils";

	// props
	export let location: MapLocation;
	export let index: number;
	export let pinsContainer: HTMLDivElement;

	// if cluster, get number of terminals in cluster
	let terminalCount: number;
	$: terminalCount = location?.terminalNames?.length
		? location.terminalNames.length
		: 0;

	// dom element refs
	let container: HTMLDivElement;
	let label: HTMLDialogElement;
	let pin: HTMLDivElement;

	// pin coordinates
	$: x = location.map.x;
	$: y = location.map.y;

	// pin rotation -- up for southern / down for northern
	let pinRotation: number;
	$: pinRotation = location.map.y < 50 ? -45 : 135;

	// correcting for pin rotation (so it points to the right spot)
	let translateX: number;
	let translateY: number;
	$: translateX = -8.15;
	$: translateY = location.map.y < 50 ? -20 : 3.7;

	// positioning number for clusters
	let numberOffsetX: number;
	let numberOffsetY: number;
	$: numberOffsetX = location.map.x < 50 ? 0.625 : -0.875;
	$: numberOffsetY = location.map.y < 50 ? 0.375 : -0.75;

	// reactive props
	$: labelWidth = 0;
	$: labelHeight = 0;
	$: labelOffsetX = 0;
	$: labelOffsetY = 0;

	function closeMenu() {
		$liquidsMenuOpen = false;
	}

	// functions
	function showLabel() {
		const allLabels = pinsContainer.querySelectorAll("dialog");
		const allPins = pinsContainer.querySelectorAll("div");

		// close menu
		if (!isEmbedded) closeMenu();

		// show this map label, hide other map labels
		allLabels?.forEach((l) => {
			l == label ? l.setAttribute("open", "") : l.removeAttribute("open");
		});

		// highlight this pin, remove highlight from other pins
		allPins?.forEach((p) => {
			p == pin
				? p.classList.add("!bg-primary")
				: p.classList.remove("!bg-primary");
		});

		// highlight this pin
		// if (pin) pin.classList.add("!bg-primary");

		//adjust label positioning
		labelWidth = label.offsetWidth;
		labelHeight = label.offsetHeight;
		if (x > 50) {
			labelOffsetX = -1 * labelWidth;
		} else labelOffsetX = 1;
		labelOffsetY = y < 50 ? 0 : -1 * labelHeight;
	}

	function toggleLabel() {
		const isOpen = label.hasAttribute("open");
		isOpen ? hideAllMapLabels() : showLabel();
	}

	// styles
	$: pinContainerStyle = `
		top: ${y}%;
		left: ${x}%;
		transform: translate(${translateX}px, ${translateY}px);
	`;

	$: dialogStyle = `
		top: ${y}%;
		left: ${x}%;
		transform: translate(${labelOffsetX}px, ${labelOffsetY}px);
	`;
</script>

<template lang="pug">
	div(
		class!="{ isEmbedded || $locationsFilteredStore.includes(location) ? 'opacity-100' : 'opacity-0 pointer-events-none' }",
		data-component!="TerminalsMapPin",
		id!="TerminalsMapPin-{index}"
	)
		//- map pin is a button which opens a popover
		//svelte-ignore a11y-click-events-have-key-events
		button.absolute.w-4.h-4.cursor-pointer.group(
			aria-label!="{ location.name }",
			on:click!="{ toggleLabel }",
			style!="{ pinContainerStyle }"
		)
			TerminalsMapPin(
				bind:pin!="{ pin }",
				name!="{ location.name }",
				pinRotation!="{ pinRotation }"
			)
			+if('terminalCount')
				TerminalsMapClusterCount(
					clusterCount!="{ terminalCount }",
					numberOffsetX!="{ numberOffsetX }",
					numberOffsetY!="{ numberOffsetY }"
				)

		TerminalMapPopover(
			bind:label!="{ label }",
			labelOffsetX!="{ labelOffsetX }",
			labelOffsetY!="{ labelOffsetY }",
			location!="{ location }",
			x!="{ x }",
			y!="{ y }"
		)
</template>
