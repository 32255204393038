<!--
@component
Here's some documentation for this component.
-->

<script lang="ts">
	// types
	import type { MapLocation } from "$types/types";

	// utils
	import { slugify } from "$utils/utils";

	// props
	export let label: HTMLDialogElement;
	export let location: MapLocation;
	export let y: number;
	export let x: number;
	export let labelOffsetX: number;
	export let labelOffsetY: number;

	$: dialogStyle = `
		top: ${y}%;
		left: ${x}%;
		transform: translate(${labelOffsetX}px, ${labelOffsetY}px);
	`;

	let terminal: string;
</script>

<template lang="pug">
	svelte:options(immutable!="{ true }")
	//- popover
	dialog.rounded-md.pin-label.bg-white.pointer-events.none.opacity-0.absolute.py-1.px-2.transition-opacity.m-0.z-50(
		class="open:pointer-events-auto open:opacity-100 open:shadow",
		bind:this!="{ label }",
		data-sveltekit-preload-data="hover",
		style!="{ dialogStyle }"
	)
		//- if it is a cluster
		+if('location.terminalNames && location?.terminalNames[0]')
			+each('location.terminalNames as terminalName')
				a.text-primary-dark.select-none.outline-none.block(
					class="hover:underline underline-offset-2 text-sub",
					href!="/terminals/{slugify(terminalName)}"
				) { terminalName }
			//- else if it is a single terminal
			+else
				a.text-primary-dark.select-none.outline-none.block(
					class="hover:underline underline-offset-2 text-sub leading-snug truncate",
					href!="/terminals/{slugify(location.name)}",
					title!="go to {location.name }"
				) { location.name }
</template>
