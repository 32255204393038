<script lang="ts">
	// context api
	import { setContext } from "svelte";

	// components
	import MapNorthAmerica from "$atoms/MapNorthAmerica.svelte";
	import TerminalsMapPinAndLabel from "$atoms/TerminalsMapPinAndLabel.svelte";

	// types
	import type { MapLocation } from "$types/types";

	// refs
	let pinsContainer: HTMLDivElement;

	// props
	export let isEmbedded: boolean;
	export let locations: MapLocation[];

	// context
	setContext("isEmbedded", isEmbedded);

	// variables
	let location: MapLocation;
	let index: number;
</script>

<template lang="pug">
	section#map.px-0.pb-6.w-full(
		class!=["{(isEmbedded) ? 'sm:px-6 md:pb-0 xl:pt-0 xl:px-8' : 'page-x-padding sm:pb-1'}"]
	)
		//- inner
		.mt-8.flex.items-center.justify-center.py-4.pointer-events-none(
			class!="{ isEmbedded ? 'bg-transparent' : 'bg-neutral-300' }"
		)
			//- map container
			.relative(
				class!="{ isEmbedded ? 'w-full sm:w-[80%] lg:w-[90%]' : 'w-[95%] sm:w-[90%] md:w-[60%]' }"
			)
				//- map image
				MapNorthAmerica

				//- map pins
				.absolute.inset-0(bind:this!="{ pinsContainer }")
					+each('locations as location, index')
						TerminalsMapPinAndLabel(
							index!="{ index }",
							location!="{ location }",
							pinsContainer!="{ pinsContainer }"
						)</template>
