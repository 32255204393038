// types
import type { TerminalAbridged, TerminalMapLocation } from "$types/types";
import { MapLocation } from "$types/types";

//- getFilteredLocations
//- getFilteredTerminals
//- getLiquids
//- getNonClusterTerminals
//- getStates
//- sortLocations
//- hideAllMapLabels

export function getFilteredLocations(
	locations: MapLocation[],
	selectedLiquid: string,
	allIsSelected: boolean,
) {
	const filteredLocations = allIsSelected
		? locations
		: locations.filter((l) => l?.liquids?.includes(selectedLiquid));
	const sorted = filteredLocations.sort((a, b) => sortLocations(a, b));
	return sorted;
}
export function getFilteredTerminals(
	terminals: TerminalAbridged[],
	selectedLiquid: string,
	allIsSelected: boolean,
) {
	// return terminals that match selected liquid
	// then remove duplicates & sort alphabetically
	const filteredTerminals = allIsSelected
		? terminals
		: terminals.filter((t) => t.liquids.includes(selectedLiquid));
	return filteredTerminals;
}
export function getLiquidsFromTerminals(terminals: TerminalAbridged[]) {
	// get all liquids from terminals data
	// then remove duplicates & sort alphabetically
	const allLiquids = terminals.map((t) => t.liquids).flat();
	const uniqueLiquids = Array.from(new Set(allLiquids));
	const sortedLiquids = uniqueLiquids.sort();
	return ["All Liquids", ...sortedLiquids];
}
export function getNonClusterTerminals(terminals: TerminalAbridged[]) {
	return terminals.filter((t) => !t.cluster);
}
export function getStatesFromTerminals(terminals: TerminalAbridged[]) {
	// get all states from terminals data
	// then remove duplicates & sort alphabetically
	const length = terminals.length;
	const allStates = terminals
		.map((t) => (t?.address?.state ? t.address.state : ""))
		.flat();
	const uniqueStates = Array.from(new Set(allStates));
	const sortedStates = uniqueStates.sort();
	return sortedStates;
}
export function sortLocations(a: TerminalMapLocation, b: TerminalMapLocation) {
	return a.name < b.name ? -1 : 1;
}
export function hideAllMapLabels() {
	/* eslint-disable  @typescript-eslint/no-explicit-any */
	const labels: HTMLElement[] = Array.from(
		document.querySelectorAll("#map dialog"),
	);
	const pins: HTMLDivElement[] = Array.from(document.querySelectorAll(".pin"));
	labels.forEach((item) => {
		//item.close();
		item.removeAttribute("open");
	});
	pins.forEach((pin) => {
		pin.classList.remove("!bg-primary");
	});
}

// use reduce function to return array of location names
export function getMapLocationNames(terminals: TerminalAbridged[]) {
	// array to hold location names + serve as reducer initial value
	const names: string[] = [];

	// reduce terminals array to get location names
	const locationNames = terminals.reduce((accumulator, terminal) => {
		if (terminal.cluster) {
			// get terminal cluster name
			accumulator.push(terminal.cluster);
		} else {
			// get terminal name
			accumulator.push(terminal.name);
		}
		return accumulator;
	}, names);

	// use spread operator to remove duplicate location names
	const uniqueLocationNames = [...new Set(locationNames)];

	// sort location names alphabetically
	const sortedNames = uniqueLocationNames.sort();
	return sortedNames;
}

// test if location is a cluster
export function locationIsCluster(locationName: string): boolean {
	return locationName.toLowerCase().includes("cluster");
}

// return array of terminals in a cluster
export function getAllTerminalsInCluster(
	clusterName: string,
	terminals: TerminalAbridged[],
) {
	return terminals.filter((t) => t.cluster === clusterName);
}

// return array of terminal names
export function getTerminalNames(terminals: TerminalAbridged[]) {
	const names = terminals.map((t) => t.name);
	const sorted = names.sort();
	return sorted;
}

// return array of terminal names in a cluster
export function getTerminalsNamesInCluster(
	clusterName: string,
	terminals: TerminalAbridged[],
) {
	const clusterTerminals = getAllTerminalsInCluster(clusterName, terminals);
	const clusterTerminalNames = getTerminalNames(clusterTerminals);
	return clusterTerminalNames;
}

// return array of all liquids in a cluster using abridged terminals data
export function getLiquidsInCluster(
	clusterName: string,
	terminals: TerminalAbridged[],
) {
	const clusterTerminals = getAllTerminalsInCluster(clusterName, terminals);
	const liquids = clusterTerminals.map((t) => t.liquids).flat();
	const uniqueLiquids = Array.from(new Set(liquids));
	const sortedLiquids = uniqueLiquids.sort();
	return sortedLiquids;
}

export function lookupTerminalByName(
	terminals: TerminalAbridged[],
	terminalName: string,
) {
	return terminals.find((t) => t.name === terminalName);
}

export function createMapLocationFromClusterName(
	locationName: string,
	terminals: TerminalAbridged[],
) {
	const mapLocation = new MapLocation(locationName);
	mapLocation.liquids = getLiquidsInCluster(locationName, terminals);
	const terminalNames = getTerminalsNamesInCluster(locationName, terminals);
	mapLocation.terminalNames = terminalNames;
	const firstTerminal = lookupTerminalByName(terminals, terminalNames[0]);
	mapLocation.map = firstTerminal ? firstTerminal.map : { x: 0, y: 0 };
	// can't serialize a class so return a clone
	// inspired by: https://www.okupter.com/blog/sveltekit-cannot-stringify-arbitrary-non-pojos-error

	// return structuredClone(mapLocation);
	return JSON.parse(JSON.stringify(mapLocation));
}
export function createMapLocationFromTerminalName(
	locationName: string,
	terminals: TerminalAbridged[],
) {
	const terminal = lookupTerminalByName(terminals, locationName);
	const mapLocation = new MapLocation(locationName);
	mapLocation.liquids = terminal?.liquids ? terminal.liquids : [];
	mapLocation.terminalNames = undefined;
	mapLocation.map =
		terminal?.map.x && terminal?.map.y ? terminal.map : { x: 0, y: 0 };
	mapLocation.slug = terminal?.slug ? terminal.slug : "";

	// can't serialize a class so return a clone
	// inspired by: https://www.okupter.com/blog/sveltekit-cannot-stringify-arbitrary-non-pojos-error
	// return structuredClone(mapLocation);
	return JSON.parse(JSON.stringify(mapLocation));
}

export function getMapLocations(terminals: TerminalAbridged[]) {
	// array of unique location names
	const locationNames = getMapLocationNames(terminals);

	// array to hold location objects
	const locations: MapLocation[] = [];

	locationNames.forEach((locationName) => {
		if (locationIsCluster(locationName)) {
			const mapLocation = createMapLocationFromClusterName(
				locationName,
				terminals,
			);
			locations.push(mapLocation);
		} else {
			const terminal = lookupTerminalByName(terminals, locationName);
			if (terminal) {
				const mapLocation = createMapLocationFromTerminalName(
					locationName,
					terminals,
				);
				locations.push(mapLocation);
			}
		}
	});
	return locations;
}
