<!--
@component
Here's some documentation for this component.
-->

<script lang="ts">
	// refs
	export let pin: HTMLDivElement;

	// props
	export let pinRotation = 0;
	export let name = "";
</script>

<template lang="pug">
	.pin.relative.pointer-events-auto.shadow.w-full.h-full.border.border-neutral-100(
		class="bg-glaucous rounded-t-full rounded-br-full transition-colors group-hover:bg-primary",
		bind:this!="{ pin }",
		data-component!="TerminalsMapPin",
		data-name!="{ name }",
		style="transform: rotate({pinRotation}deg)"
	)</template>
